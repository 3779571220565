







































import { Vue, Component, Prop } from 'vue-property-decorator'
import {BButton, BCard, BCol, BFormGroup, BFormInput, BRow, BTab, BTabs} from "bootstrap-vue";
import EmployeeSearchSelect from "@/apps/components/employee-search-select.vue";
import {VForm} from "@/global";

@Component({
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BButton,
    BFormGroup,
    BFormInput,
    EmployeeSearchSelect,
  }
})
export default class EmployeeAssignAllRegistrationsFromEmployee extends Vue {
  $refs!: {
    form: VForm
  }

  oldEmployee: EmployeeAPI.User.User = null;
  newEmployee: EmployeeAPI.User.User = null;

  ressignResponse: RegistrationsAPI.Maintenance.AssignAllRegistrationsResponse = null;

  async submit() {
    this.ressignResponse = null;

    if (!await this.$refs.form.validate()) return;

    this.ressignResponse = await this.$api.registrations.maintenance.assignAllRegistrationsFromEmployee(this.oldEmployee.id, this.newEmployee.id)

    this.oldEmployee = null;
    this.newEmployee = null;

    this.$refs.form.reset();
  }
}

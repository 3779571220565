





































import { Vue, Component, Prop } from 'vue-property-decorator'
import {VForm} from "@/global";
import {BButton, BCard, BCol, BFormGroup, BFormInput, BRow, BTab, BTabs} from "bootstrap-vue";
import EmployeeSearchSelect from "@/apps/components/employee-search-select.vue";

@Component({
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BButton,
    BFormGroup,
    BFormInput,
    EmployeeSearchSelect,
  }
})
export default class EmployeeAssignSingleRegistration extends Vue {
  $refs!: {
    form: VForm
  }

  employee: EmployeeAPI.User.User = null;
  registrationId: string = null;



  async submit() {
    if (!await this.$refs.form.validate()) return;

    await this.$api.registrations.maintenance.assignEmployee(this.registrationId, this.employee.id)
    this.employee = null;
    this.registrationId = null;

    this.$refs.form.reset();
  }
}

















import {Vue, Component, Prop} from 'vue-property-decorator'
import {BCard, BTab, BTabs} from "bootstrap-vue";

import EmployeeAssignSingleRegistration  from "./EmployeeAssignSingleRegistration/index.vue";
import EmployeeAssignAllRegistrationsFromEmployee  from "./EmployeeAssignAllRegistrationsFromEmployee/index.vue";

@Component({
  components: {
    BCard,
    BTabs,
    BTab,
    EmployeeAssignSingleRegistration,
    EmployeeAssignAllRegistrationsFromEmployee
  }
})
export default class RegistrationMaintenanceAssignEmployee extends Vue {

}
